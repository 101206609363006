<template>
    <div class="ProjectPage">
        <div style="height: 1px" />
        <div class="logo">
            <img src="@/assets/img/MilitarCivilianPersonnel/14.png" alt="" />
        </div>
        <div class="top_img" @click="abuyy()">
            <img src="@/assets/img/MilitarCivilianPersonnel/9.png" alt="" />
        </div>
        <div class="mainbox">
            <div class="box1">
                <div class="box_til">
                    <div class="box_til_top">军队文职<span>早知道</span> </div>
                    <div class="box_til_bot">J U N D U I W E N Z H I Z A O Z H I D A O </div>
                </div>
                <div class="box1_top">
                    <span
                        style="color: #033952;font-weight: bold;">中国人民解放军文职人员，</span>是指在军民通用、非直接参与作战且社会化保障不宜承担的军队编制岗位从事管理工作
                    和专业技术工作的非现役人员，是军队人员的组成部分。主要在军级以上机关和非作战部队，从事教育科研、工程
                    实验、医疗卫生、文艺体育等<span style="font-weight: 500;">专业技术和事务性管理保障工作。</span>
                </div>
                <div class="box1_bot">
                    <div class="box1_bot_item">
                        <div class="item_top"></div>
                        <div class="item_mid">考试<span>竞争小</span> </div>
                        <div class="item_bot">笔试不考申论，相对于公务员更容易。专业课考察知识范围明确且有针对性，竞争压力相对较小。</div>
                    </div>
                    <div class="box1_bot_item b2">
                        <div class="item_top"></div>
                        <div class="item_mid">福利<span>待遇好</span> </div>
                        <div class="item_bot">
                            文职人员福利待遇与现役军官相同，按照现行标准，工资构成包括基本薪资、津贴补贴、奖励工资等。五险一金纳入国家社会保障体系。更有福利抚恤、健康体检、探亲休假、子女入托、等多项福利。
                        </div>
                    </div>
                    <div class="box1_bot_item b2">
                        <div class="item_top"></div>
                        <div class="item_mid">编制<span>稳定</span> </div>
                        <div class="item_bot">军队文职人员经过统一考试合格聘用，用人单位与其签订聘用合同，只要表现好，能够胜任工作，原则上都可以续聘，稳定工作到退休。</div>
                    </div>
                    <div class="box1_bot_item">
                        <div class="item_top"></div>
                        <div class="item_mid">晋升<span>快</span> </div>
                        <div class="item_bot">管理岗位设9个等级，专业技术岗位共设13级。工作满足工作年限要求和年度考核的，可以在本单位文职人员编制标准内晋升岗位等级。</div>
                    </div>
                </div>
            </div>
            <div class="box1 box2">
                <div class="box_til">
                    <div class="box_til_top">军队文职<span>招录条件</span> </div>
                    <div class="box_til_bot">J U N D U I W E N Z H I Z H A O L U T I A O J I A N</div>
                </div>
                <div class="box1_bot">
                    <div class="box1_bot_item">
                        <div class="bo2_item1">
                            <div class="bo2_item1_til">招 录 对 象</div>
                            <div>
                                <p>1）普通高等学校毕业生（含毕业后2年内</p>
                                <p> 未择业的）</p>
                                <p>2）社会人员</p>
                                <p>3）定向招考对象（军队烈士、因公牺牲军</p>
                                <p>人的配偶子女，未婚军队烈士的兄弟姐妹，</p>
                                <p>以及现役军人配偶）</p>
                            </div>
                        </div>
                    </div>
                    <div class="box1_bot_item">

                        <div class="bo2_item1 bo2_item2">
                            <div class="bo2_item1_til">学 历 条 件</div>
                            <div>
                                <p> 1）报考人员应当具有普通高等学校全日制本科</p>
                                <p>以上学历可以不作全日制要求的：报考在艰苦边</p>
                                <p>远地区、岛屿的岗位，特殊岗位</p>
                                <p>2）学历可放宽至大学专科：驻艰苦边远地区、岛</p>
                                <p>屿的单位定向招考军队烈士、因公牺牲军人的配偶</p>
                                <p>子女以及现役军人配偶，除教学、科研、工程、医</p>
                                <p>疗专业技术岗位外</p>
                                <p>3）可为大学专科以上学历：报考护理、艺术、体</p>
                                <p>育岗位的。</p>
                            </div>
                        </div>
                    </div>
                    <div class="box1_bot_item">
                        <div class="bo2_item1 bo2_item3">
                            <div class="bo2_item1_til">身 体 条 件</div>
                            <div>
                                <p>1）应当符合《军队聘用文职人员体格检查通用</p>
                                <p>标准》规定的标准条件。</p>
                                <p>2）身高：男性身高160cm以上，</p>
                                <p>女性身高155cm以上</p>
                                <p>3）体重：体重符合下列条件的，合格：</p>
                                <p>男性：不超过标准体重的30%、不低于标准体</p>
                                <p>重的15%</p>
                                <p>女性：不超过标准体重的20%、不低于标准体</p>
                                <p>重的15%（标准体重kg=身高cm-110）</p>
                                <p>4）视力： 任意一眼矫正视力低于0.8(标准对数</p>
                                <p>视力4.9)，不合格；明显视功能损害眼病者，不</p>
                                <p>合格。</p>
                            </div>
                        </div>
                    </div>
                    <div class="box1_bot_item">

                        <div class="bo2_item1 bo2_item3">
                            <div class="bo2_item1_til">政 治 条 件</div>
                            <div>
                                <p>七种不合格情况</p>
                                <p>1）编造或传播有政治性问题言论</p>
                                <p>2）参加、支持民族分裂、暴力恐怖、宗教极</p>
                                <p>端等非法组织和活动</p>
                                <p>3） 被开除公职、责令辞职、开除党籍、开除学</p>
                                <p>籍、开除团籍或受到严重纪律处分</p>
                                <p>4） 家庭成员、主要社会关系成员因危害国家安</p>
                                <p>全受到刑事处罚或为非法组织成员</p>
                                <p>5）与国（境）外政治背景复杂的组织或人员有</p>
                                <p>联系，被有关部门记录在案</p>
                                <p>6）有涉及淫秽、色情、暴力和非法组织标志等纹身</p>
                                <p>7）曾受到刑事处罚、行政拘留等</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box1 box3">
                <div class="box_til">
                    <div class="box_til_top">专业报考指导<span>贴心选岗规划</span> </div>
                    <div class="box_til_bot">Z H U A N Y E B A O K A O Z H I D A O T I E X I N X U A N G A N G G U I H U
                        A
                    </div>
                </div>
                <div>
                    <el-form class="format" ref="phoneForm" :rules="rules" :model="form" label-width="0px">
                        <el-form-item class="province" label="" prop="address">
                            <el-select filterable v-model="form.address" placeholder="请选择">
                                <el-option v-for="(item, index) in province" :key="index" :label="item.areaName"
                                    :value="item.areaName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="phone" label="" prop="mobile">
                            <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item class="code" label="" prop="sjToken">
                            <div class="codeBox">
                                <el-input v-model="form.sjToken" placeholder="请输入验证码"></el-input>
                                <button type="button" class="boxbssHdBtn" :class="time > 0 ? 'notClick' : ''"
                                    @click="getPhoneCode" :disable="disable">
                                    {{ titles }}
                                </button>
                            </div>
                        </el-form-item>
                        <el-form-item class="btn" label="" prop="mobile">
                            <div @click="submits">报考咨询</div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="box1 box4">
                <div class="box_til">
                    <div class="box_til_top">军队文职<span>招录流程</span> </div>
                    <div class="box_til_bot">J U N D U I W E N Z H I Z H A O L U L I U C H E N G</div>
                </div>
                <div class="box4_connent">
                    <div class="box4_connent_img">
                        <img src="@/assets/img/MilitarCivilianPersonnel/7.png" alt="">
                    </div>
                </div>
            </div>
            <div class="box1 box4">
                <div class="box_til">
                    <div class="box_til_top">军队文职<span>考情介绍</span> </div>
                    <div class="box_til_bot">J U N D U I W E N Z H I K A O Q I N G J I E S H A O </div>
                </div>
                <div class="box4_connent">
                    <div style="height: 220px;" class="box4_connent_img">
                        <img src="@/assets/img/MilitarCivilianPersonnel/8.png" alt="">
                    </div>
                </div>
            </div>
            <div class="box1 box4 box5">
                <div class="box_til">
                    <div class="box_til_top">我们的<span>课程优势</span> </div>
                    <div class="box_til_bot">W O M E N D E K E C H E N G Y O U S H I </div>
                </div>
                <div class="box5_list">
                    <div>名师团队</div>
                    <div>细致全面</div>
                    <div>实力研发</div>
                    <div>考点精讲</div>
                </div>
                <div class="box5_imglist">
                    <div><img src="@/assets/img/MilitarCivilianPersonnel/3.png" alt=""></div>
                    <div><img src="@/assets/img/MilitarCivilianPersonnel/1.png" alt=""></div>
                    <div><img src="@/assets/img/MilitarCivilianPersonnel/2.png" alt=""></div>
                </div>
            </div>
        </div>

        <div v-if="isShowRegin" @click="isShowRegin = false" class="pageAll">
            <div class="upDio">
                <div class="upDio_til">信息提交成功！请注意接听电话，稍后老师为您提供一对一服务！</div>
                <div class="upDio_btn">知道了</div>
            </div>
        </div>
    </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { loginSmsCode, getAreaFuList, registerStudentJXU } from "@/api/login";
import { pcUserBindAcc } from "@/api/user";
export default {
    components: {},

    data() {
        return {
            isShowRegin: false,
            disable: false,
            time: 0,
            province: [],
            userId: null,
            titles: "获取验证码",
            form: {
                mobile: "",
                sjToken: "",
                address: "",
            },

            rules: {
                mobile: [
                    { required: true, message: "手机号不能为空", trigger: "blur" },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入合法手机号",
                        trigger: "blur",
                    },
                ],
                sjToken: [{ required: true, message: "请输入验证码", trigger: "blur" }],
                address: [{ required: true, message: "请选择省份", trigger: "change" }],
            },
        };
    },
    created() {
        this.userId = localStorage.getItem("userId");
        this.getAreaFuList()
    },
    methods: {
        getAreaFuList() {
            getAreaFuList().then(res => {
                this.province = res.data
            })
        },
        open() {
            this.$refs.phoneForm.validate(async (valid) => {
                if (valid) {
                    pcUserBindAcc(this.form.mobile, this.form.sjToken).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                type: "success",
                                message: "绑定成功，请重新登录",
                            });
                            this.$emit("isOk");
                            setTimeout(() => {
                                Vue.prototype.goLoginView(false);
                            }, 2000);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        cancel() {
            this.$emit("isOk");
        },
        // 注册验证码
        getPhoneCode() {
            if (this.time > 0) {
                return;
            }
            if (!/^[1]([3-9])[0-9]{9}$/.test(this.form.mobile)) {
                this.$message.error("手机号格式不正确");
                return;
            }
            loginSmsCode(this.form.mobile, "confirmToken").then((res) => {
                if (res.code == 0) {
                    this.time = 60;
                    this.$message.success(res.msg);
                    var wws = setInterval(() => {
                        if (this.time < 1) {
                            this.titles = "获取验证码";
                            this.disable = false;
                            clearInterval(wws);
                        } else {
                            this.titles = this.time-- + "秒后重发";
                            this.disable = true;
                        }
                    }, 1000);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 短信登录
        submits() {
            this.$refs.phoneForm.validate(async (valid) => {
                if (valid) {
                    registerStudentJXU(
                        {
                            tel: this.form.mobile,
                            token: this.form.sjToken,
                            address: this.form.address,
                            userSource: '军队文职'
                        }
                    ).then((res) => {
                        if (res.code == 0) {
                            this.isShowRegin = true

                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.ProjectPage {
    width: 100%;
    // height: 5226px;
    position: relative;
    background-image: url("~@/assets/img/MilitarCivilianPersonnel/13.png");
    background-size: 100% 100%;
    padding-bottom: 50px;

    .top_img {
        width: 991px;
        height: 464px;
        margin: 0 auto;
        margin-top: 98px;
        perspective: 1000px;

        img {
            animation: rotate 2s;
        }
    }

    .logo {
        width: 188px;
        height: 69px;
        position: absolute;
        left: 361px;
        top: 30px;
    }

    .mainbox {
        width: 1220px;
        margin: 0 auto;

        .box1 {
            width: 100%;
            border: 1px solid #FBE9A9;
            padding: 40px;
            border-radius: 8px;
            background: #14799A;

            .box1_top {
                width: 1128px;
                height: 144px;
                background: #FCD172;
                border-radius: 5px;
                padding: 27px 60px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                margin-top: 30px;
                color: #033952;
                line-height: 1.5;
            }

            .box1_bot {
                width: 1128px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 23px;

                .box1_bot_item {
                    position: relative;
                    margin-bottom: 20px;

                    .item_top {
                        width: 550px;
                        height: 64px;
                        background: #FCD172;
                        border-radius: 6px 6px 6px 0;
                    }

                    .item_mid {
                        width: 222px;
                        height: 62px;
                        line-height: 62px;
                        background: #033952;
                        border-radius: 0 6px 6px 0;
                        position: absolute;
                        top: 43px;
                        padding-left: 30px;

                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;

                        span {
                            color: #FCD172;

                        }
                    }

                    .item_bot {
                        width: 550px;
                        height: 254px;
                        background: #FCD172;
                        border-radius: 0 6px 6px 6px;
                        margin-top: 20px;
                        padding: 48px 37px;

                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #033952;
                        line-height: 36px;
                    }
                }

                .b2 {
                    .item_top {
                        background: #FDEED0;

                    }

                    .item_bot {
                        background: #FDEED0;

                    }
                }
            }
        }

        .box2 {
            margin-top: 20px;

            .bo2_item1 {
                position: relative;
                width: 540px;
                height: 389px;
                background: url('~@/assets/img/MilitarCivilianPersonnel/15.png');
                padding: 47px 10px 0 90px;
                line-height: 1.5;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #033952;

                .bo2_item1_til {
                    position: absolute;
                    left: 19px;
                    top: 30px;
                    width: 34px;

                    height: 175px;
                    font-size: 34px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #F8F9FA;
                    line-height: 47px;
                }

            }

            .bo2_item2 {
                background: url('~@/assets/img/MilitarCivilianPersonnel/15.png');
            }

            .bo2_item3 {
                position: relative;
                width: 540px;
                height: 487px;
                background: url('~@/assets/img/MilitarCivilianPersonnel/4.png');
                padding: 47px 20px 0 90px;

                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #033952;
            }
        }

        .box3 {
            background: #149A90;
            margin-top: 38px;
        }

        .box4 {
            margin-top: 38px;

            .box4_connent {
                width: 1130px;
                height: 268px;
                background: #FAF5F5;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 36px;

                .box4_connent_img {
                    width: 1010px;
                    height: 172px;

                }
            }
        }

        .box5 {
            .box5_list {
                display: flex;
                width: 1120px;

                justify-content: space-between;
                margin: 0 auto;
                margin-top: 40px;

                >div {
                    width: 260px;
                    height: 87px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    line-height: 87px;
                    text-align: center;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #14799A;
                }
            }

            .box5_imglist {
                display: flex;
                width: 1120px;
                justify-content: space-between;
                margin: 0 auto;
                margin-top: 56px;

                >div {
                    width: 348px;
                    height: 200px;
                }
            }
        }

        .box_til {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;

            .box_til_top {

                font-size: 51px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FEFEFE;

                span {
                    color: #FDD982
                }
            }

            .box_til_bot {

                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FEFEFE;
            }
        }
    }

    .format {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;

        .province {
            width: 277px;
        }

        .phone {
            width: 340px;
        }

        .code {
            width: 251px;

            ::v-deep .el-input__inner {
                font-size: 18px;
            }

        }

        .btn {
            width: 185px;

            div {
                width: 185px;
                height: 70px;
                line-height: 70px;
                background: #FCD172;
                border-radius: 5px;
                text-align: center;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                cursor: pointer;
            }
        }

        ::v-deep .el-input__inner {

            height: 70px;
            line-height: 70px;

            font-size: 22px;
        }
    }


}

img {
    width: 100%;
    height: 100%;
}

@keyframes rotate {
    0% {
        transform: rotateY(0deg) translateZ(-100px);
    }

    100% {
        transform: rotateY(360deg) translateZ(0px);
    }
}

.pageAll {
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    // opacity: 0.5;
}

.upDio {
    position: fixed;

    padding: 46px 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    height: 207px;
    background: #FEFEFE;
    border-radius: 5px;
    text-align: center;
    line-height: 1.5;

    .upDio_til {

        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .upDio_btn {
        width: 150px;
        height: 47px;
        background: #FCD172;
        border-radius: 5px;
        line-height: 47px;
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 30px;
        cursor: pointer;
    }

    img {
        width: 100%;
    }
}


.codeBox {
    position: relative;

    .boxbssHdBtn {
        position: absolute;
        right: 14px;
        top: 15px;
        width: 98px;
        height: 42px;
        background: #FCD172;
        border-radius: 5px;
        cursor: pointer;
        flex-shrink: 0;
        line-height: 42px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        outline: none;
        text-align: center;
        border: none;
    }
}
</style>
